import { countryList, lang } from "util/constants";

const stepsTur = {
  1: {
    simple: {
      step1: [
        {
          title: "Dil",
          subtitle: "Toplanacak incelemelerin dili",
          valueType: "dropdown",
          defaultValue: "Turkish",
          acceptedValues: lang,
          placeholder: "Dilini Seç",
          key: "language",
        },
        {
          title: "Konu Sayısı",
          subtitle: "Keşfedilecek maksimum konu sayısını belirtin",
          valueType: "numeric",
          placeholder: "Konu sayısını girin (İsteğe bağlı)",
          key: "num_topics",
          required: false,
        },
      ],
      step2: [
        {
          title: "Herhangi bir anahtar kelime",
          subtitle:
            "Yorumlar bu anahtar kelimelerden herhangi birini içerir. Örnek: Herhangi bir anahtar kelime olarak köpek ve kedinin seçilmesi durumunda, toplanan tweet'ler ya köpek, kedi ya da her ikisini birden içerecektir.",
          valueType: "multiInput",
          key: "any_keywords",
        },
        {
          title: "Bütün Anahtar Kelimeler",
          subtitle:
            "Yorumlar bu anahtar kelimelerin tümünü içerir. Örnek: Herhangi bir anahtar kelime olarak köpek ve kedi seçilmesi durumunda, toplanan tweet'ler kesinlikle aynı tweet'te köpek ve kedi anahtar kelimesini içerecektir.",
          valueType: "multiInput",
          key: "all_keywords",
        },
        {
          title: "Yasaklı Hesaplar",
          subtitle: "Bu Twitter hesaplarından gelen yorumlar görüntülenmeyecek",
          valueType: "multiInput",
          key: "banned_accounts",
        },
      ],
    },
    advanced: {
      step1: [
        {
          title: "Dil",
          subtitle: "Toplanacak incelemelerin dili",
          valueType: "dropdown",
          defaultValue: "Turkish",
          acceptedValues: lang,
          placeholder: "Dilini Seç",
          key: "language",
        },
        {
          title: "Konu Sayısı",
          subtitle: "Keşfedilecek maksimum konu sayısını belirtin",
          valueType: "numeric",
          placeholder: "Konu sayısını girin (İsteğe bağlı)",
          key: "num_topics",
          required: false,
        },
      ],
      step2: [
        {
          title: "Herhangi bir anahtar kelime",
          subtitle: "Yorumlar bu anahtar kelimelerden herhangi birini içerir.",
          valueType: "multiInput",
          key: "any_keywords",
        },
        {
          title: "Bütün Anahtar Kelimeler",
          subtitle: "Yorumlar bu anahtar kelimelerin hepsini içerir.",
          valueType: "multiInput",
          key: "all_keywords",
        },
        {
          title: "Yasaklı Hesaplar",
          subtitle: "Bu Twitter hesaplarından gelen yorumlar görüntülenmeyecek",
          valueType: "multiInput",
          key: "banned_accounts",
        },
      ],
      step3: [
        {
          title: "algoritma",
          subtitle: "Konu oluşturmak için eğitim algoritmasını seçin",
          valueType: "radio",
          acceptedValues: [
            { text: "Kısa metin", key: 4 },
            { text: "Uzun metin", key: 3 },
          ],
          key: "algorithm_id",
        },
        {
          title: "Yineleme Sayısı",
          subtitle: "Eğitimdeki yineleme sayısını seçin",
          valueType: "numeric",
          placeholder: "Yineleme Sayısı girin",
          defaultValue: 100,
          key: "num_iters",
        },
        {
          title: "Alfa",
          subtitle: "Eğitimde alfa değerini seçin",
          valueType: "input",
          placeholder: "Alfa Değeri girin",
          key: "alpha",
        },
        {
          title: "Beta",
          subtitle: "Eğitimde Beta değerini seçin",
          valueType: "numeric",
          placeholder: "Beta Değeri girin",
          key: "beta",
        },
      ],
    },
  },
  2: {
    simple: {
      step1: [
        {
          title: "Dil",
          subtitle: "Toplanacak incelemelerin dili",
          valueType: "dropdown",
          defaultValue: "Turkish",
          acceptedValues: lang,
          placeholder: "Dilini Seç",
          key: "language",
        },
        {
          title: "Ülke",
          subtitle: "İncelemeleri ülkelere göre filtrele",
          valueType: "dropdownMulti",
          acceptedValues: countryList,
          placeholder: "Ülkenizi seçin",
          key: "countries",
        },
      ],
      step2: [
        {
          title: "Uygulama URL'si",
          subtitle: "Mağazada bulunan uygulama url'sini yazın",
          valueType: "input",
          placeholder: "Uygulama URL'sini girin",
          key: "app_url",
          required: true,
        },
        {
          title: "Keşfedilecek Konu Sayısı",
          subtitle: "Keşfedilecek maksimum konu sayısını belirtin",
          valueType: "numeric",
          placeholder: "Keşfedilecek Konu Sayısıni girin (isteğe bağlı)",
          key: "num_topics",
          required: false,
        },
        {
          title: "Başlangıç Tarihi",
          subtitle: "Şu tarihten beri yayınlanan yorumlar",
          valueType: "date",
          key: "start_date",
        },
        {
          title: "Bitiş Tarihi",
          subtitle: "Yorumlar şu tarihe kadar gönderildi",
          valueType: "date",
          key: "end_date",
        },
      ],
    },
    advanced: {
      step1: [
        {
          title: "Dil",
          subtitle: "Toplanacak incelemelerin dili",
          valueType: "dropdown",
          defaultValue: "Turkish",
          acceptedValues: lang,
          placeholder: "Dilini Seç",
          key: "language",
        },
        {
          title: "Ülke",
          subtitle: "İncelemeleri ülkelere göre filtrele",
          valueType: "dropdownMulti",
          acceptedValues: countryList,
          placeholder: "Ülkenizi seçin",
          key: "countries",
        },
      ],
      step2: [
        {
          title: "Uygulama URL'si",
          subtitle: "Mağazada bulunan uygulama url'sini yazın",
          valueType: "input",
          placeholder: "Uygulama URL'sini girin",
          key: "app_url",
          required: true,
        },
        {
          title: "Konu Sayısı",
          subtitle: "Keşfedilecek maksimum konu sayısını belirtin",
          valueType: "numeric",
          placeholder: "Konu sayısını girin (İsteğe bağlı)",
          key: "num_topics",
          required: false,
        },
        {
          title: "Başlangıç Tarihi",
          subtitle: "Getirilen belgelerin başlangıç tarihi",
          valueType: "date",
          key: "start_date",
        },
        {
          title: "Bitiş Tarihi",
          subtitle: "Getirilen belgelerin bitiş tarihi",
          valueType: "date",
          key: "end_date",
        },
      ],
      step3: [
        {
          title: "algoritma",
          subtitle: "Konu oluşturmak için eğitim algoritmasını seçin",
          valueType: "radio",
          acceptedValues: [
            { text: "Kısa metin", key: 4 },
            { text: "Uzun metin", key: 3 },
          ],
          key: "algorithm_id",
        },
        {
          title: "Yineleme Sayısı",
          subtitle: "Eğitimdeki yineleme sayısını seçin",
          valueType: "numeric",
          placeholder: "Yineleme Sayısı girin",
          defaultValue: 100,
          key: "num_iters",
        },
        {
          title: "Alfa",
          subtitle: "Eğitimde alfa değerini seçin",
          valueType: "input",
          placeholder: "Alfa Değeri girin",
          key: "alpha",
        },
        {
          title: "Beta",
          subtitle: "Eğitimde Beta değerini seçin",
          valueType: "numeric",
          placeholder: "Beta Değeri girin",
          key: "beta",
        },
      ],
    },
  },
  3: {
    simple: {
      step1: [
        {
          title: "Dashboard Adı",
          subtitle: "Dashboard Adını Yazın",
          valueType: "input",
          placeholder: "Dashboard adını girin",
          key: "dashboard_name",
          required: true,
        },
        {
          title: "Dil",
          subtitle: "Toplanacak incelemelerin dili",
          valueType: "dropdown",
          defaultValue: "Turkish",
          acceptedValues: lang,
          placeholder: "Dilini Seç",
          key: "language",
          required: false,
        },
        {
          title: "Konu Sayısı",
          subtitle: "Keşfedilecek maksimum konu sayısını belirtin",
          valueType: "numeric",
          placeholder: "Konu sayısını girin (İsteğe bağlı)",
          key: "num_topics",
          required: false,
        },
      ],
      step2: [
        {
          valueType: "customerFile",
          placeholder: "Bir dosya seçin veya yükleyin",
          key: "customerDataFileS3Link",
          required: true,
        },
      ],
      step3: [
        {
          title: "Dosyanız başlık içeriyor mu",
          subtitle:
            "Sonraki adımlara ilerlemek için dosyanızın sütun adlarına sahip olması gerekir",
          valueType: "radio",
          acceptedValues: [
            { text: "Hayır", key: "false" },
            { text: "Evet", key: "true" },
          ],
          key: "headersQuestion",
          required: true,
          disableIfNo: true,
        },
      ],
      step4: [
        {
          title: "Geri Bildirim Sütun Adları",
          subtitle: "Geri bildirim sütun adını yüklenen/seçilen dosyaya yazın",
          valueType: "dropdown",
          placeholder: "Ad girin",
          key: "customerFeedbackTextIndex",
          required: true,
        },
      ],
      step5: [
        {
          title: "Müşteri Kimliği Sütun Adı",
          subtitle:
            "Müşteri Kimliği Sütun Adını yüklenen/seçilen dosyaya yazın",
          valueType: "dropdown",
          placeholder: "Ad girin",
          key: "customerUserIdIndex",
          skipAll: true,
        },
      ],
      // step6: [
      //    {
      //       title: "E-posta Kimliği Sütun Adı",
      //       subtitle: "E-posta Kimliği Sütun Adını yüklenen/seçilen dosyaya yazın",
      //       valueType: "dropdown",
      //       placeholder: "Ad girin",
      //       key: "customerUserEmailndex",
      //       skipAll: true,
      //    },
      // ],
      step6: [
        {
          title: "Tarih Sütun Adı",
          subtitle:
            "Tarih formatı YYYY-AA-GG veya YYYY-AA-GG SS:AA:SS olmalıdır",
          valueType: "dropdown",
          placeholder: "Ad girin",
          key: "customerDateIndex",
          skipAll: true,
        },
      ],
      // step8: [
      //    {
      //       title: "Telefon Sütun Adı",
      //       subtitle: "Telefon Sütun Adını yüklenen/seçilen dosyaya yazın",
      //       valueType: "dropdown",
      //       placeholder: "Ad girin",
      //       key: "customerUserPhoneIndex",
      //       skipAll: true,
      //    },
      // ],
      step7: [
        {
          title: "Derecelendirme Sütunu Adı",
          subtitle:
            "Derecelendirme Sütunu Adını yüklenen/seçilen dosyaya yazın",
          valueType: "dropdown",
          placeholder: "Ad girin",
          key: "customerRatingIndex",
          skipAll: true,
        },
      ],
      step8: [
        {
          title: "Soru Sütunu Adı",
          subtitle:
            "Sağlanırsa, AI motoru hem soru metnine hem de cevaba dayalı olarak tahminde bulunur",
          valueType: "dropdown",
          placeholder: "Ad girin",
          key: "customerQuestionIndex",
          skipAll: true,
        },
      ],
      step9: [
        {
          title: "Pivot Sütun",
          subtitle:
            "Müşteri kullanım süresi, segment, satıcı adı, kanal adı ve daha fazlası gibi inceleme özelliklerine dayalı toplu hesaplamalar oluşturmak için pivot değerleri seçin.",
          valueType: "multiselect",
          placeholder: "Sütun adlarını seç (10'a kadar)",
          key: "filtercolIndex",
        },
      ],
    },
    advanced: {
      step1: [
        {
          title: "Dashboard Adı",
          subtitle: "Dashboard Adını Yazın",
          valueType: "input",
          placeholder: "Dashboard adını girin",
          key: "dashboard_name",
          required: true,
        },
        {
          title: "Dil",
          subtitle: "Toplanacak incelemelerin dili",
          valueType: "dropdown",
          defaultValue: "Turkish",
          acceptedValues: lang,
          placeholder: "Dilini Seç",
          key: "language",
          required: true,
        },
        {
          title: "Konu Sayısı",
          subtitle: "Keşfedilecek maksimum konu sayısını belirtin",
          valueType: "numeric",
          placeholder: "Konu sayısını girin (İsteğe bağlı)",
          key: "num_topics",
          required: false,
        },
      ],
      step2: [
        {
          title: "algoritma",
          subtitle: "Konu oluşturmak için eğitim algoritmasını seçin",
          valueType: "radio",
          acceptedValues: [
            { text: "Kısa metin", key: 4 },
            { text: "Uzun metin", key: 3 },
          ],
          key: "algorithm_id",
        },
        {
          title: "Yineleme Sayısı",
          subtitle: "Eğitimdeki yineleme sayısını seçin",
          valueType: "numeric",
          placeholder: "Yineleme Sayısı girin",
          defaultValue: 100,
          key: "num_iters",
        },
        {
          title: "Alfa",
          subtitle: "Eğitimde alfa değerini seçin",
          valueType: "input",
          placeholder: "Alfa Değeri girin",
          key: "alpha",
        },
        {
          title: "Beta",
          subtitle: "Eğitimde Beta değerini seçin",
          valueType: "numeric",
          placeholder: "Beta Değeri girin",
          key: "beta",
        },
      ],
      step3: [
        {
          valueType: "customerFile",
          placeholder: "Bir dosya seçin veya yükleyin",
          key: "customerDataFileS3Link",
          required: true,
        },
      ],
      step4: [
        {
          title: "Dosyanız başlık içeriyor mu?",
          subtitle:
            "Sonraki adımlara ilerlemek için dosyanızın sütun adlarına sahip olması gerekir",
          valueType: "radio",
          acceptedValues: [
            { text: "Hayır", key: "false" },
            { text: "Evet", key: "true" },
          ],
          key: "headersQuestion",
          required: true,
          disableIfNo: true,
        },
      ],
      step5: [
        {
          title: "Geri Bildirim Sütun Adları",
          subtitle: "Geri bildirim sütun adını yüklenen/seçilen dosyaya yazın",
          valueType: "dropdown",
          placeholder: "Ad girin",
          key: "customerFeedbackTextIndex",
          required: true,
        },
      ],
      step6: [
        {
          title: "Müşteri Kimliği Sütun Adı",
          subtitle:
            "Müşteri Kimliği Sütun Adını yüklenen/seçilen dosyaya yazın",
          valueType: "dropdown",
          placeholder: "Ad girin",
          key: "customerUserIdIndex",
          skipAll: true,
        },
      ],
      // step7: [
      //   {
      //     title: "E-posta Kimliği Sütun Adı",
      //     subtitle:
      //       "E-posta Kimliği Sütun Adını yüklenen/seçilen dosyaya yazın",
      //     valueType: "dropdown",
      //     placeholder: "Ad girin",
      //     key: "customerUserEmailndex",
      //     skipAll: true,
      //   },
      // ],
      step7: [
        {
          title: "Tarih Sütun Adı",
          subtitle:
            "Tarih formatı YYYY-AA-GG veya YYYY-AA-GG SS:AA:SS olmalıdır",
          valueType: "dropdown",
          placeholder: "Ad girin",
          key: "customerDateIndex",
          skipAll: true,
        },
      ],
      // step9: [
      //   {
      //     title: "Telefon Sütun Adı",
      //     subtitle: "Telefon Sütun Adını yüklenen/seçilen dosyaya yazın",
      //     valueType: "dropdown",
      //     placeholder: "Ad girin",
      //     key: "customerUserPhoneIndex",
      //     skipAll: true,
      //   },
      // ],
      step8: [
        {
          title: "Derecelendirme Sütunu Adı",
          subtitle:
            "Derecelendirme Sütunu Adını yüklenen/seçilen dosyaya yazın",
          valueType: "dropdown",
          placeholder: "Ad girin",
          key: "customerRatingIndex",
          skipAll: true,
        },
      ],
      step9: [
        {
          title: "Soru Sütunu Adı",
          subtitle:
            "Sağlanırsa, AI motoru hem soru metnine hem de cevaba dayalı olarak tahminde bulunur",
          valueType: "dropdown",
          placeholder: "Ad girin",
          key: "customerQuestionIndex",
          skipAll: true,
        },
      ],
      step10: [
        {
          title: "Pivot Sütun",
          subtitle:
            "Müşteri kullanım süresi, segment, satıcı adı, kanal adı ve daha fazlası gibi inceleme özelliklerine dayalı toplu hesaplamalar oluşturmak için pivot değerleri seçin.",
          valueType: "multiselect",
          placeholder: "Sütun adlarını seç (10'a kadar)",
          key: "filtercolIndex",
        },
      ],
    },
  },
  4: {
    simple: {
      step1: [
        {
          title: "Dil",
          subtitle: "Toplanacak incelemelerin dili",
          valueType: "dropdown",
          defaultValue: "Turkish",
          acceptedValues: lang,
          placeholder: "Dilini Seç",
          key: "language",
        },
        {
          title: "Ülke",
          subtitle: "İncelemeleri ülkelere göre filtrele",
          valueType: "dropdownMulti",
          acceptedValues: countryList,
          placeholder: "Ülkenizi seçin",
          key: "countries",
        },
      ],
      step2: [
        {
          title: "Uygulama URL'si",
          subtitle: "Mağazada bulunan uygulama url'sini yazın",
          valueType: "input",
          placeholder: "Uygulama URL'sini girin",
          key: "app_url",
          required: true,
        },
        {
          title: "Konu Sayısı",
          subtitle: "Keşfedilecek maksimum konu sayısını belirtin",
          valueType: "numeric",
          placeholder: "Konu sayısını girin (İsteğe bağlı)",
          key: "num_topics",
          required: false,
        },
        {
          title: "Başlangıç Tarihi",
          subtitle: "Getirilen belgelerin başlangıç tarihi",
          valueType: "date",
          key: "start_date",
        },
        {
          title: "Bitiş Tarihi",
          subtitle: "Getirilen belgelerin bitiş tarihi",
          valueType: "date",
          key: "end_date",
        },
      ],
    },
    advanced: {
      step1: [
        {
          title: "Dil",
          subtitle: "Toplanacak incelemelerin dili",
          valueType: "dropdown",
          defaultValue: "Turkish",
          acceptedValues: lang,
          placeholder: "Dilini Seç",
          key: "language",
        },
        {
          title: "Ülke",
          subtitle: "İncelemeleri ülkelere göre filtrele",
          valueType: "dropdownMulti",
          acceptedValues: countryList,
          placeholder: "Ülkenizi seçin",
          key: "countries",
        },
      ],
      step2: [
        {
          title: "Uygulama URL'si",
          subtitle: "Mağazada bulunan uygulama url'sini yazın",
          valueType: "input",
          placeholder: "Uygulama URL'sini girin",
          key: "app_url",
          required: true,
        },
        {
          title: "Konu Sayısı",
          subtitle: "Keşfedilecek maksimum konu sayısını belirtin",
          valueType: "numeric",
          placeholder: "Konu sayısını girin (İsteğe bağlı)",
          key: "num_topics",
          required: false,
        },
        {
          title: "Başlangıç Tarihi",
          subtitle: "Getirilen belgelerin başlangıç tarihi",
          valueType: "date",
          key: "start_date",
        },
        {
          title: "Bitiş Tarihi",
          subtitle: "Getirilen belgelerin bitiş tarihi",
          valueType: "date",
          key: "end_date",
        },
      ],
      step3: [
        {
          title: "algoritma",
          subtitle: "Konu oluşturmak için eğitim algoritmasını seçin",
          valueType: "radio",
          acceptedValues: [
            { text: "Kısa metin", key: 4 },
            { text: "Uzun metin", key: 3 },
          ],
          key: "algorithm_id",
        },
        {
          title: "Yineleme Sayısı",
          subtitle: "Eğitimdeki yineleme sayısını seçin",
          valueType: "numeric",
          placeholder: "Yineleme Sayısı girin",
          defaultValue: 100,
          key: "num_iters",
        },
        {
          title: "Alfa",
          subtitle: "Eğitimde alfa değerini seçin",
          valueType: "input",
          placeholder: "Alfa Değeri girin",
          key: "alpha",
        },
        {
          title: "Beta",
          subtitle: "Eğitimde Beta değerini seçin",
          valueType: "numeric",
          placeholder: "Beta Değeri girin",
          key: "beta",
        },
      ],
    },
  },
  5: {
    simple: {
      step1: [
        {
          title: "Marka adı",
          subtitle: "Marka Adı yazın",
          valueType: "input",
          placeholder: "Marka Adı girin",
          key: "brand",
          required: true,
        },
        {
          title: "Konu Sayısı",
          subtitle: "Keşfedilecek maksimum konu sayısını belirtin",
          valueType: "numeric",
          placeholder: "Konu sayısını girin (İsteğe bağlı)",
          key: "num_topics",
          required: false,
        },
        {
          title: "Başlangıç Tarihi",
          subtitle: "Getirilen belgelerin başlangıç tarihi",
          valueType: "date",
          key: "start_date",
        },
        {
          title: "Bitiş Tarihi",
          subtitle: "Getirilen belgelerin bitiş tarihi",
          valueType: "date",
          key: "end_date",
        },
      ],
    },
    advanced: {
      step1: [
        {
          title: "Marka adı",
          subtitle: "Marka Adı yazın",
          valueType: "input",
          placeholder: "Marka Adı girin",
          key: "brand",
          required: true,
        },
        {
          title: "Konu Sayısı",
          subtitle: "Keşfedilecek maksimum konu sayısını belirtin",
          valueType: "numeric",
          placeholder: "Konu sayısını girin (İsteğe bağlı)",
          key: "num_topics",
          required: false,
        },
        {
          title: "Başlangıç Tarihi",
          subtitle: "Getirilen belgelerin başlangıç tarihi",
          valueType: "date",
          key: "start_date",
        },
        {
          title: "Bitiş Tarihi",
          subtitle: "Getirilen belgelerin bitiş tarihi",
          valueType: "date",
          key: "end_date",
        },
      ],
      step2: [
        {
          title: "algoritma",
          subtitle: "Konu oluşturmak için eğitim algoritmasını seçin",
          valueType: "radio",
          acceptedValues: [
            { text: "Kısa metin", key: 4 },
            { text: "Uzun metin", key: 3 },
          ],
          key: "algorithm_id",
        },
        {
          title: "Yineleme Sayısı",
          subtitle: "Eğitimdeki yineleme sayısını seçin",
          valueType: "numeric",
          placeholder: "Yineleme Sayısı girin",
          defaultValue: 100,
          key: "num_iters",
        },
        {
          title: "Alfa",
          subtitle: "Eğitimde alfa değerini seçin",
          valueType: "input",
          placeholder: "Alfa Değeri girin",
          key: "alpha",
        },
        {
          title: "Beta",
          subtitle: "Eğitimde Beta değerini seçin",
          valueType: "numeric",
          placeholder: "Beta Değeri girin",
          key: "beta",
        },
      ],
    },
  },
  6: {
    simple: {
      step1: [
        {
          title: "Dil",
          subtitle: "Toplanacak incelemelerin dili",
          valueType: "dropdown",
          defaultValue: "Turkish",
          acceptedValues: lang,
          placeholder: "Dilini Seç",
          key: "language",
        },
      ],
      step2: [
        {
          title: "HashTags",
          subtitle: "Yorumlar bu hashtag'lerden herhangi birini içeriyor",
          valueType: "multiInput",
          key: "hashtags",
          required: true,
        },
        {
          title: "Konu Sayısı",
          subtitle: "Keşfedilecek maksimum konu sayısını belirtin",
          valueType: "numeric",
          placeholder: "Konu sayısını girin (İsteğe bağlı)",
          key: "num_topics",
          required: false,
        },
      ],
    },
    advanced: {
      step1: [
        {
          title: "Dil",
          subtitle: "Toplanacak incelemelerin dili",
          valueType: "dropdown",
          defaultValue: "Turkish",
          acceptedValues: lang,
          placeholder: "Dilini Seç",
          key: "language",
        },
      ],
      step2: [
        {
          title: "HashTag",
          subtitle: "Aradığınız hashtag'i yazın",
          valueType: "input",
          placeholder: "Aradığınız hashtag'i girin",
          key: "hashtag",
          required: true,
        },
        {
          title: "Konu Sayısı",
          subtitle: "Keşfedilecek maksimum konu sayısını belirtin",
          valueType: "numeric",
          placeholder: "Konu sayısını girin (İsteğe bağlı)",
          key: "num_topics",
          required: false,
        },
      ],
      step3: [
        {
          title: "algoritma",
          subtitle: "Konu oluşturmak için eğitim algoritmasını seçin",
          valueType: "radio",
          acceptedValues: [
            { text: "Kısa metin", key: 4 },
            { text: "Uzun metin", key: 3 },
          ],
          key: "algorithm_id",
        },
        {
          title: "Yineleme Sayısı",
          subtitle: "Eğitimdeki yineleme sayısını seçin",
          valueType: "numeric",
          placeholder: "Yineleme Sayısı girin",
          defaultValue: 100,
          key: "num_iters",
        },
        {
          title: "Alfa",
          subtitle: "Eğitimde alfa değerini seçin",
          valueType: "input",
          placeholder: "Alfa Değeri girin",
          key: "alpha",
        },
        {
          title: "Beta",
          subtitle: "Eğitimde Beta değerini seçin",
          valueType: "numeric",
          placeholder: "Beta Değeri girin",
          key: "beta",
        },
      ],
    },
  },
  7: {
    simple: {
      step1: [
        {
          title: "Dashboard Adı",
          subtitle: "Dashboard Adını Yazın",
          valueType: "input",
          placeholder: "Dashboard adını girin",
          key: "dashboard_name",
          required: true,
        },
        {
          title: "Analiz türü",
          subtitle: "Ana hedefinize göre özel analizler alın",
          valueType: "dropdown",
          defaultValue: "Tickets Content Analysis",
          acceptedValues: [
            "Tickets Content Analysis",
            "Customer Satisfaction Post-Survey Analysis (CSAT)",
          ],
          placeholder: "Analiz amacınızı seçin",
          key: "zendesk_analysis_target",
          required: true,
        },
      ],
      step2: [
        {
          title: "Dil",
          subtitle: "Toplanacak incelemelerin dili",
          valueType: "dropdown",
          defaultValue: "Turkish",
          acceptedValues: lang,
          placeholder: "Dilini Seç",
          key: "language",
        },
        {
          title: "Konu Sayısı",
          subtitle: "Keşfedilecek maksimum konu sayısını belirtin",
          valueType: "numeric",
          placeholder: "Konu sayısını girin (İsteğe bağlı)",
          key: "num_topics",
          required: false,
        },
        {
          title: "Başlangıç Tarihi",
          subtitle: "Şu tarihten beri yayınlanan yorumlar",
          valueType: "date",
          key: "start_date",
        },
        {
          title: "Bitiş Tarihi",
          subtitle: "Yorumlar şu tarihe kadar gönderildi:",
          valueType: "date",
          key: "end_date",
        },
      ],
    },
    advanced: {
      step1: [
        {
          title: "Dashboard Adı",
          subtitle: "Dashboard Adını Yazın",
          valueType: "input",
          placeholder: "Dashboard adını girin",
          key: "dashboard_name",
          required: true,
        },
        {
          title: "Analiz türü",
          subtitle: "Get tailored analysis based on your main goal",
          valueType: "dropdown",
          defaultValue: "Tickets Content Analysis",
          acceptedValues: [
            "Tickets Content Analysis",
            "Customer Satisfaction Post-Survey Analysis (CSAT)",
          ],
          placeholder: "Select your analysis purpose",
          key: "zendesk_analysis_target",
          required: true,
        },
      ],
      step2: [
        {
          title: "Dil",
          subtitle: "Toplanacak incelemelerin dili",
          valueType: "dropdown",
          defaultValue: "Turkish",
          acceptedValues: lang,
          placeholder: "Dilini Seç",
          key: "language",
        },
        {
          title: "Konu Sayısı",
          subtitle: "Keşfedilecek maksimum konu sayısını belirtin",
          valueType: "numeric",
          placeholder: "Konu sayısını girin (İsteğe bağlı)",
          key: "num_topics",
          required: false,
        },
        {
          title: "Başlangıç Tarihi",
          subtitle: "Şu tarihten beri yayınlanan yorumlar",
          valueType: "date",
          key: "start_date",
        },
        {
          title: "Bitiş Tarihi",
          subtitle: "Yorumlar şu tarihe kadar gönderildi:",
          valueType: "date",
          key: "end_date",
        },
      ],
      step3: [
        {
          title: "algoritma",
          subtitle: "Konu oluşturmak için eğitim algoritmasını seçin",
          valueType: "radio",
          acceptedValues: [
            { text: "Kısa metin", key: 4 },
            { text: "Uzun metin", key: 3 },
          ],
          key: "algorithm_id",
        },
        {
          title: "Yineleme Sayısı",
          subtitle: "Eğitimdeki yineleme sayısını seçin",
          valueType: "numeric",
          placeholder: "Yineleme Sayısı girin",
          defaultValue: 100,
          key: "num_iters",
        },
        {
          title: "Alfa",
          subtitle: "Eğitimde alfa değerini seçin",
          valueType: "input",
          placeholder: "Alfa Değeri girin",
          key: "alpha",
        },
        {
          title: "Beta",
          subtitle: "Eğitimde Beta değerini seçin",
          valueType: "numeric",
          placeholder: "Beta Değeri girin",
          key: "beta",
        },
      ],
    },
  },
  8: {
    simple: {
      step1: [
        {
          title: "Dashboard Adı",
          subtitle: "Dashboard Adını Yazın",
          valueType: "input",
          placeholder: "Dashboard adını girin",
          key: "dashboard_name",
          required: true,
        },
        {
          title: "Dil",
          subtitle: "Toplanacak incelemelerin dili",
          valueType: "dropdown",
          defaultValue: "Turkish",
          acceptedValues: lang,
          placeholder: "Dilini Seç",
          key: "language",
        },
        {
          title: "Konu Sayısı",
          subtitle: "Keşfedilecek maksimum konu sayısını belirtin",
          valueType: "numeric",
          placeholder: "Konu sayısını girin (İsteğe bağlı)",
          key: "num_topics",
          required: false,
        },
        {
          title: "Başlangıç Tarihi",
          subtitle: "Şu tarihten beri yayınlanan yorumlar",
          valueType: "date",
          key: "start_date",
        },
        {
          title: "Bitiş Tarihi",
          subtitle: "Yorumlar şu tarihe kadar gönderildi:",
          valueType: "date",
          key: "end_date",
        },
      ],
    },
    advanced: {
      step1: [
        {
          title: "Dashboard Adı",
          subtitle: "Dashboard Adını Yazın",
          valueType: "input",
          placeholder: "Dashboard adını girin",
          key: "dashboard_name",
          required: true,
        },
        {
          title: "Dil",
          subtitle: "Toplanacak incelemelerin dili",
          valueType: "dropdown",
          defaultValue: "Turkish",
          acceptedValues: lang,
          placeholder: "Dilini Seç",
          key: "language",
        },
        {
          title: "Konu Sayısı",
          subtitle: "Keşfedilecek maksimum konu sayısını belirtin",
          valueType: "numeric",
          placeholder: "Konu sayısını girin (İsteğe bağlı)",
          key: "num_topics",
          required: false,
        },
        {
          title: "Başlangıç Tarihi",
          subtitle: "Şu tarihten beri yayınlanan yorumlar",
          valueType: "date",
          key: "start_date",
        },
        {
          title: "Bitiş Tarihi",
          subtitle: "Yorumlar şu tarihe kadar gönderildi:",
          valueType: "date",
          key: "end_date",
        },
      ],
      step2: [
        {
          title: "algoritma",
          subtitle: "Konu oluşturmak için eğitim algoritmasını seçin",
          valueType: "radio",
          acceptedValues: [
            { text: "Kısa metin", key: 4 },
            { text: "Uzun metin", key: 3 },
          ],
          key: "algorithm_id",
        },
        {
          title: "Yineleme Sayısı",
          subtitle: "Eğitimdeki yineleme sayısını seçin",
          valueType: "numeric",
          placeholder: "Yineleme Sayısı girin",
          defaultValue: 100,
          key: "num_iters",
        },
        {
          title: "Alfa",
          subtitle: "Eğitimde alfa değerini seçin",
          valueType: "input",
          placeholder: "Alfa Değeri girin",
          key: "alpha",
        },
        {
          title: "Beta",
          subtitle: "Eğitimde Beta değerini seçin",
          valueType: "numeric",
          placeholder: "Beta Değeri girin",
          key: "beta",
        },
      ],
    },
  },
  9: {
    simple: {
      step1: [
        {
          title: "Dashboard Adı",
          subtitle: "Dashboard Adını Yazın",
          valueType: "input",
          placeholder: "Dashboard adını girin",
          key: "dashboard_name",
          required: true,
        },
        {
          title: "Dil",
          subtitle: "Toplanacak incelemelerin dili",
          valueType: "dropdown",
          defaultValue: "Turkish",
          acceptedValues: lang,
          placeholder: "Dilini Seç",
          key: "language",
        },
        {
          title: "Konu Sayısı",
          subtitle: "Keşfedilecek maksimum konu sayısını belirtin",
          valueType: "numeric",
          placeholder: "Konu sayısını girin (İsteğe bağlı)",
          key: "num_topics",
          required: false,
        },
      ],
      step2: [
        {
          title: "Sayfa ismi",
          subtitle: "Facebook Sayfası ismi",
          valueType: "dropdown_2",
          acceptedValues: [],
          placeholder: "sayfanı seç",
          key: "page_id",
          required: true,
        },
        {
          title: "Başlangıç Tarihi",
          subtitle: "Şu tarihten beri yayınlanan yorumlar",
          valueType: "date",
          key: "start_date",
        },
        {
          title: "Bitiş Tarihi",
          subtitle: "Yorumlar şu tarihe kadar gönderildi:",
          valueType: "date",
          key: "end_date",
        },
      ],
    },
    advanced: {
      step1: [
        {
          title: "Dashboard Adı",
          subtitle: "Dashboard Adını Yazın",
          valueType: "input",
          placeholder: "Dashboard adını girin",
          key: "dashboard_name",
          required: true,
        },
        {
          title: "Dil",
          subtitle: "Toplanacak incelemelerin dili",
          valueType: "dropdown",
          defaultValue: "Turkish",
          acceptedValues: lang,
          placeholder: "Dilini Seç",
          key: "language",
        },
        {
          title: "Konu Sayısı",
          subtitle: "Keşfedilecek maksimum konu sayısını belirtin",
          valueType: "numeric",
          placeholder: "Konu sayısını girin (İsteğe bağlı)",
          key: "num_topics",
          required: false,
        },
      ],
      step2: [
        {
          title: "Sayfa ismi",
          subtitle: "Facebook Sayfası ismi",
          valueType: "dropdown_2",
          acceptedValues: [],
          placeholder: "sayfanı seç",
          key: "page_id",
          required: true,
        },
        {
          title: "Başlangıç Tarihi",
          subtitle: "Şu tarihten beri yayınlanan yorumlar",
          valueType: "date",
          key: "start_date",
        },
        {
          title: "Bitiş Tarihi",
          subtitle: "Yorumlar şu tarihe kadar gönderildi:",
          valueType: "date",
          key: "end_date",
        },
      ],
      step3: [
        {
          title: "algoritma",
          subtitle: "Konu oluşturmak için eğitim algoritmasını seçin",
          valueType: "radio",
          acceptedValues: [
            { text: "Kısa metin", key: 4 },
            { text: "Uzun metin", key: 3 },
          ],
          key: "algorithm_id",
        },
        {
          title: "Yineleme Sayısı",
          subtitle: "Eğitimdeki yineleme sayısını seçin",
          valueType: "numeric",
          placeholder: "Yineleme Sayısı girin",
          defaultValue: 100,
          key: "num_iters",
        },
        {
          title: "Alfa",
          subtitle: "Eğitimde alfa değerini seçin",
          valueType: "input",
          placeholder: "Alfa Değeri girin",
          key: "alpha",
        },
        {
          title: "Beta",
          subtitle: "Eğitimde Beta değerini seçin",
          valueType: "numeric",
          placeholder: "Beta Değeri girin",
          key: "beta",
        },
      ],
    },
  },
  10: {
    simple: {
      step1: [
        {
          title: "Dashboard Adı",
          subtitle: "Dashboard Adını Yazın",
          valueType: "input",
          placeholder: "Dashboard adını girin",
          key: "dashboard_name",
          required: true,
        },
        {
          title: "Dil",
          subtitle: "Toplanacak incelemelerin dili",
          valueType: "dropdown",
          defaultValue: "Turkish",
          acceptedValues: lang,
          placeholder: "Dilini Seç",
          key: "language",
        },
        {
          title: "Konu Sayısı",
          subtitle: "Keşfedilecek maksimum konu sayısını belirtin",
          valueType: "numeric",
          placeholder: "Konu sayısını girin (İsteğe bağlı)",
          key: "num_topics",
          required: false,
        },
      ],
      step2: [
        {
          title: "hesap ekle",
          subtitle: "Facebook Reklam Hesabının Kimliği",
          valueType: "dropdown_2",
          acceptedValues: [],
          placeholder: "Reklam Hesabını seç",
          key: "ad_account_id",
          required: true,
        },
        {
          title: "Başlangıç Tarihi",
          subtitle: "Şu tarihten beri yayınlanan yorumlar",
          valueType: "date",
          key: "start_date",
        },
        {
          title: "Bitiş Tarihi",
          subtitle: "Yorumlar şu tarihe kadar gönderildi:",
          valueType: "date",
          key: "end_date",
        },
      ],
    },
    advanced: {
      step1: [
        {
          title: "Dashboard Adı",
          subtitle: "Dashboard Adını Yazın",
          valueType: "input",
          placeholder: "Dashboard adını girin",
          key: "dashboard_name",
          required: true,
        },
        {
          title: "Dil",
          subtitle: "Toplanacak incelemelerin dili",
          valueType: "dropdown",
          defaultValue: "Turkish",
          acceptedValues: lang,
          placeholder: "Dilini Seç",
          key: "language",
        },
        {
          title: "Konu Sayısı",
          subtitle: "Keşfedilecek maksimum konu sayısını belirtin",
          valueType: "numeric",
          placeholder: "Konu sayısını girin (İsteğe bağlı)",
          key: "num_topics",
          required: false,
        },
      ],
      step2: [
        {
          title: "hesap ekle",
          subtitle: "Facebook Reklam Hesabının Kimliği",
          valueType: "dropdown_2",
          acceptedValues: [],
          placeholder: "Reklam Hesabını seç",
          key: "ad_account_id",
          required: true,
        },
        {
          title: "Başlangıç Tarihi",
          subtitle: "Şu tarihten beri yayınlanan yorumlar",
          valueType: "date",
          key: "start_date",
        },
        {
          title: "Bitiş Tarihi",
          subtitle: "Yorumlar şu tarihe kadar gönderildi:",
          valueType: "date",
          key: "end_date",
        },
      ],
      step3: [
        {
          title: "algoritma",
          subtitle: "Konu oluşturmak için eğitim algoritmasını seçin",
          valueType: "radio",
          acceptedValues: [
            { text: "Kısa metin", key: 4 },
            { text: "Uzun metin", key: 3 },
          ],
          key: "algorithm_id",
        },
        {
          title: "Yineleme Sayısı",
          subtitle: "Eğitimdeki yineleme sayısını seçin",
          valueType: "numeric",
          placeholder: "Yineleme Sayısı girin",
          defaultValue: 100,
          key: "num_iters",
        },
        {
          title: "Alfa",
          subtitle: "Eğitimde alfa değerini seçin",
          valueType: "input",
          placeholder: "Alfa Değeri girin",
          key: "alpha",
        },
        {
          title: "Beta",
          subtitle: "Eğitimde Beta değerini seçin",
          valueType: "numeric",
          placeholder: "Beta Değeri girin",
          key: "beta",
        },
      ],
    },
  },
  11: {
    simple: {
      step1: [
        {
          title: "Dashboard Adı",
          subtitle: "Dashboard Adını Yazın",
          valueType: "input",
          placeholder: "Dashboard adını girin",
          key: "dashboard_name",
          required: true,
        },
        {
          title: "Dil",
          subtitle: "Toplanacak incelemelerin dili",
          valueType: "dropdown",
          defaultValue: "Turkish",
          acceptedValues: lang,
          placeholder: "Dilini Seç",
          key: "language",
          required: true,
        },
        {
          title: "Ülke",
          subtitle: "İncelemeleri ülkelere göre filtrele",
          valueType: "dropdownMulti",
          acceptedValues: countryList,
          placeholder: "Ülkenizi seçin",
          key: "countries",
        },
        {
          title: "Platformlar",
          subtitle:
            "Google Play, App Store veya her ikisi arasından seçim yapın",
          valueType: "dropdownMulti",
          acceptedValues: ["Google Play", "App Store"],
          placeholder: "Platformunu seç",
          key: "platforms",
          required: true,
        },
      ],
      step2: [
        {
          title: "Google Play Uygulama URL",
          subtitle: "Google Play'de bulunan uygulama url'sini yazın",
          valueType: "input",
          placeholder: "Uygulama URL'sini girin",
          key: "google_play_app_url",
          // required: true,
        },
        {
          title: "App Store uygulama url",
          subtitle: "App Store'da bulunan uygulama url'sini yazın",
          valueType: "input",
          placeholder: "Uygulama URL'sini girin",
          key: "app_store_app_url",
          // required: true,
        },
        {
          title: "Keşfedilecek Konu Sayısı",
          subtitle: "Keşfedilecek maksimum konu sayısını belirtin",
          valueType: "numeric",
          placeholder: "Konu sayısını girin (İsteğe bağlı)",
          key: "num_topics",
          required: false,
        },
        {
          title: "Başlangıç Tarihi",
          subtitle: "Şu tarihten beri yayınlanan yorumlar",
          valueType: "date",
          key: "start_date",
        },
        {
          title: "Bitiş Tarihi",
          subtitle: "Yorumlar şu tarihe kadar gönderildi:",
          valueType: "date",
          key: "end_date",
        },
      ],
    },
    advanced: {
      step1: [
        {
          title: "Dashboard Adı",
          subtitle: "Dashboard Adını Yazın",
          valueType: "input",
          placeholder: "Dashboard adını girin",
          key: "dashboard_name",
          required: true,
        },
        {
          title: "Dil",
          subtitle: "Toplanacak incelemelerin dili",
          valueType: "dropdown",
          defaultValue: "Turkish",
          acceptedValues: lang,
          placeholder: "Dilini Seç",
          key: "language",
          required: true,
        },
        {
          title: "Ülke",
          subtitle: "İncelemeleri ülkelere göre filtrele",
          valueType: "dropdownMulti",
          acceptedValues: countryList,
          placeholder: "Ülkenizi seçin",
          key: "countries",
        },
        {
          title: "Platformlar",
          subtitle:
            "Google Play, App Store veya her ikisi arasından seçim yapın",
          valueType: "dropdownMulti",
          acceptedValues: ["Google Play", "App Store"],
          placeholder: "Platformunu seç",
          key: "platforms",
        },
      ],
      step2: [
        {
          title: "Google Play Uygulama URL",
          subtitle: "Google Play'de bulunan uygulama url'sini yazın",
          valueType: "input",
          placeholder: "Uygulama URL'sini girin",
          key: "google_play_app_url",
          // required: true,
        },
        {
          title: "App Store uygulama url",
          subtitle: "App Store'da bulunan uygulama url'sini yazın",
          valueType: "input",
          placeholder: "Uygulama URL'sini girin",
          key: "app_store_app_url",
          // required: true,
        },
        {
          title: "Konu Sayısı",
          subtitle: "Keşfedilecek maksimum konu sayısını belirtin",
          valueType: "numeric",
          placeholder: "Konu sayısını girin (İsteğe bağlı)",
          key: "num_topics",
          required: false,
        },
        {
          title: "Başlangıç Tarihi",
          subtitle: "Getirilen belgelerin başlangıç tarihi",
          valueType: "date",
          key: "start_date",
        },
        {
          title: "Bitiş Tarihi",
          subtitle: "Getirilen belgelerin bitiş tarihi",
          valueType: "date",
          key: "end_date",
        },
      ],
      step3: [
        {
          title: "algoritma",
          subtitle: "Konu oluşturmak için eğitim algoritmasını seçin",
          valueType: "radio",
          acceptedValues: [
            { text: "Kısa metin", key: 4 },
            { text: "Uzun metin", key: 3 },
          ],
          key: "algorithm_id",
        },
        {
          title: "Yineleme Sayısı",
          subtitle: "Eğitimdeki yineleme sayısını seçin",
          valueType: "numeric",
          placeholder: "Yineleme Sayısı girin",
          defaultValue: 100,
          key: "num_iters",
        },
        {
          title: "Alfa",
          subtitle: "Eğitimde alfa değerini seçin",
          valueType: "input",
          placeholder: "Alfa Değeri girin",
          key: "alpha",
        },
        {
          title: "Beta",
          subtitle: "Eğitimde Beta değerini seçin",
          valueType: "numeric",
          placeholder: "Beta Değeri girin",
          key: "beta",
        },
      ],
    },
  },
  13: {
    simple: {
      step1: [
        {
          title: "Dashboard Adı",
          subtitle: "Dashboard Adını Yazın",
          valueType: "input",
          placeholder: "Dashboard adını girin",
          key: "dashboard_name",
          required: true,
        },
        {
          title: "Dil",
          subtitle: "Toplanacak incelemelerin dili",
          valueType: "dropdown",
          defaultValue: "Turkish",
          acceptedValues: lang,
          placeholder: "Dilini Seç",
          key: "language",
          required: true,
        },
        {
          title: "Keşfedilecek Konu Sayısı",
          subtitle: "Keşfedilecek maksimum konu sayısını belirtin",
          valueType: "numeric",
          placeholder: "Konu sayısını girin (İsteğe bağlı)",
          key: "num_topics",
          required: false,
        },
        {
          title: "Başlangıç Tarihi",
          subtitle: "Getirilen belgelerin başlangıç tarihi",
          valueType: "date",
          key: "start_date",
        },
        {
          title: "Bitiş Tarihi",
          subtitle: "Getirilen belgelerin bitiş tarihi",
          valueType: "date",
          key: "end_date",
        },
      ],
      step2: [
        {
          title: "Kategori Bağlantısı",
          subtitle:
            "Analiz Etmek İstediğiniz Kategori URL'lerini Kopyalayıp Yapıştırın",
          valueType: "multiInput",
          placeholder: "Enter category URL and press enter for each link",
          key: "category_links",
          required: true,
        },
        {
          title: "Ürün linki",
          subtitle:
            "Analiz etmek istediğiniz ürün URL'lerini kopyalayıp yapıştırın",
          valueType: "multiInput",
          placeholder: "Enter product URLs and press enter for each link",
          key: "product_links",
          required: true,
        },
      ],
    },
    advanced: {
      step1: [
        {
          title: "Dashboard Adı",
          subtitle: "Dashboard Adını Yazın",
          valueType: "input",
          placeholder: "Dashboard adını girin",
          key: "dashboard_name",
          required: true,
        },
        {
          title: "Dil",
          subtitle: "Toplanacak incelemelerin dili",
          valueType: "dropdown",
          defaultValue: "Turkish",
          acceptedValues: lang,
          placeholder: "Dilini Seç",
          key: "language",
          required: true,
        },
        {
          title: "Keşfedilecek Konu Sayısı",
          subtitle: "Keşfedilecek maksimum konu sayısını belirtin",
          valueType: "numeric",
          placeholder: "Konu sayısını girin (İsteğe bağlı)",
          key: "num_topics",
          required: false,
        },
        {
          title: "Başlangıç Tarihi",
          subtitle: "Getirilen belgelerin başlangıç tarihi",
          valueType: "date",
          key: "start_date",
        },
        {
          title: "Bitiş Tarihi",
          subtitle: "Getirilen belgelerin bitiş tarihi",
          valueType: "date",
          key: "end_date",
        },
      ],
      step2: [
        {
          title: "Kategori Bağlantısı",
          subtitle:
            "Analiz Etmek İstediğiniz Kategori URL'lerini Kopyalayıp Yapıştırın",
          valueType: "multiInput",
          placeholder: "Enter category URL and press enter for each link",
          key: "category_links",
          required: true,
        },
        {
          title: "Ürün linki",
          subtitle:
            "Analiz etmek istediğiniz ürün URL'lerini kopyalayıp yapıştırın",
          valueType: "multiInput",
          placeholder: "Enter product URLs and press enter for each link",
          key: "product_links",
          required: true,
        },
      ],
      step3: [
        {
          title: "algoritma",
          subtitle: "Konu oluşturmak için eğitim algoritmasını seçin",
          valueType: "radio",
          acceptedValues: [
            { text: "Kısa metin", key: 4 },
            { text: "Uzun metin", key: 3 },
          ],
          key: "algorithm_id",
        },
        {
          title: "Yineleme Sayısı",
          subtitle: "Eğitimdeki yineleme sayısını seçin",
          valueType: "numeric",
          placeholder: "Yineleme Sayısı girin",
          defaultValue: 100,
          key: "num_iters",
        },
        {
          title: "Alfa",
          subtitle: "Eğitimde alfa değerini seçin",
          valueType: "input",
          placeholder: "Alfa Değeri girin",
          key: "alpha",
        },
        {
          title: "Beta",
          subtitle: "Eğitimde Beta değerini seçin",
          valueType: "numeric",
          placeholder: "Beta Değeri girin",
          key: "beta",
        },
      ],
    },
  },
  12: {
    simple: {
      step1: [
        {
          title: "Dashboard Adı",
          subtitle: "Dashboard Adını Yazın",
          valueType: "input",
          placeholder: "Dashboard adını girin",
          key: "dashboard_name",
          required: true,
        },
        {
          title: "Dil",
          subtitle: "Toplanacak incelemelerin dili",
          valueType: "dropdown",
          defaultValue: "Turkish",
          acceptedValues: lang,
          placeholder: "Dilini Seç",
          key: "language",
        },
        {
          title: "Başlangıç Tarihi",
          subtitle: "Şu tarihten beri yayınlanan yorumlar",
          valueType: "date",
          key: "start_date",
        },
        {
          title: "Bitiş Tarihi",
          subtitle: "Yorumlar şu tarihe kadar gönderildi:",
          valueType: "date",
          key: "end_date",
        },
      ],
      step2: [
        {
          title: "Platformlar",
          subtitle: "Sosyal Medya Platformları arasında seçim yapın",
          valueType: "dropdownMulti",
          acceptedValues: [
            "Ekşi Sözlük",
            "Facebook Page",
            "Şikayet Var",
            "Twitter",
            "Instagram Account Posts",
            "Forums/Community",
            "LinkedIn"
          ],
          placeholder: "Platformunu seç",
          key: "platforms",
          required: true,
        },
        {
          title: "Keşfedilecek Konu Sayısı",
          subtitle: "Keşfedilecek maksimum konu sayısını belirtin",
          valueType: "numeric",
          placeholder: "Konu sayısını girin (İsteğe bağlı)",
          key: "num_topics",
          required: false,
        },
      ],
      step3: [
        {
          title: "Instagram HashTags",
          subtitle: "Yorumlar bu hashtag'lerden herhangi birini içeriyor",
          valueType: "multiInput",
          key: "hashtags",
        },
      ],
    },
    advanced: {
      step1: [
        {
          title: "Dashboard Adı",
          subtitle: "Dashboard Adını Yazın",
          valueType: "input",
          placeholder: "Dashboard adını girin",
          key: "dashboard_name",
          required: true,
        },
        {
          title: "Dil",
          subtitle: "Toplanacak incelemelerin dili",
          valueType: "dropdown",
          defaultValue: "Turkish",
          acceptedValues: lang,
          placeholder: "Dilini Seç",
          key: "language",
        },
        {
          title: "Başlangıç Tarihi",
          subtitle: "Şu tarihten beri yayınlanan yorumlar",
          valueType: "date",
          key: "start_date",
        },
        {
          title: "Bitiş Tarihi",
          subtitle: "Yorumlar şu tarihe kadar gönderildi:",
          valueType: "date",
          key: "end_date",
        },
      ],
      step2: [
        {
          title: "Platformlar",
          subtitle: "Sosyal Medya Platformları arasında seçim yapın",
          valueType: "dropdownMulti",
          acceptedValues: [
            "Instagram Account Posts",
            "Ekşi Sözlük",
            "Facebook Page",
            "Şikayet Var",
            "Twitter",
            "Forums/Community",
            "LinkedIn"
          ],
          placeholder: "Platformunu seç",
          key: "platforms",
          required: true,
        },
        {
          title: "Keşfedilecek Konu Sayısı",
          subtitle: "Keşfedilecek maksimum konu sayısını belirtin",
          valueType: "numeric",
          placeholder: "Konu sayısını girin (İsteğe bağlı)",
          key: "num_topics",
          required: false,
        },
      ],
      step3: [
        {
          title: "Instagram HashTags",
          subtitle: "Yorumlar bu hashtag'lerden herhangi birini içeriyor",
          valueType: "multiInput",
          key: "hashtags",
        },
      ],
      step4: [
        {
          title: "algoritma",
          subtitle: "Konu oluşturmak için eğitim algoritmasını seçin",
          valueType: "radio",
          acceptedValues: [
            { text: "Kısa metin", key: 4 },
            { text: "Uzun metin", key: 3 },
          ],
          key: "algorithm_id",
        },
        {
          title: "Yineleme Sayısı",
          subtitle: "Eğitimdeki yineleme sayısını seçin",
          valueType: "numeric",
          placeholder: "Yineleme Sayısı girin",
          defaultValue: 100,
          key: "num_iters",
        },
        {
          title: "Alfa",
          subtitle: "Eğitimde alfa değerini seçin",
          valueType: "input",
          placeholder: "Alfa Değeri girin",
          key: "alpha",
        },
        {
          title: "Beta",
          subtitle: "Eğitimde Beta değerini seçin",
          valueType: "numeric",
          placeholder: "Beta Değeri girin",
          key: "beta",
        },
      ],
    },
  },
};

export default stepsTur;

/*
types = [radio, dropdown, dropdownMulti, input, numeric, date, ]
*/
